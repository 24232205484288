//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { delTemplate, enableTemplate, templateList } from '@/api/modular/fileStream/template'
import addForm from './components/addForm.vue'
// eslint-disable-next-line camelcase
import { init_wx } from '@/views/fileStream/documentManage/weixin/weixin'
import OpenData from '../documentManage/weixin/openData.vue'
export default {
    components: {
        addForm,
        OpenData,
    },
    data() {
        return {
            queryParam: {},
            tagActive: 0,
            nodes: [],
            currentTag: {},
            tags: [
                {
                    name: '模板名稱',
                },
            ],
        }
    },
    created() {
        init_wx()
        this.getTemplateLiist()
    },
    methods: {
        formatNodeList(nodeList) {
            return nodeList.map((node) => ({ ...node, type: this.getDataType(node) }))
        },
        confirm({ id }) {
            delTemplate(id).then((res) => {
                if (res.code === 200) {
                    this.$message.success('刪除成功')
                    this.getTemplateLiist()
                } else {
                    this.$message.error(res.message)
                }
            })
        },
        changeActive(tag, tagIndex) {
            this.currentTag = tag
            this.nodes = tag.nodeList
            this.tagActive = tagIndex
        },
        getTemplateLiist(refresh) {
            templateList().then((res) => {
                if (res.code === 200) {
                    this.tags = [...res.data]
                    if (refresh) {
                        const index = this.tags.length - 1
                        const obj = this.tags[index]
                        this.nodes = obj.nodeList
                        this.currentTag = obj
                        this.tagActive = index
                    } else if (this.tags && this.tags.length > 0) {
                        this.nodes = this.tags[0].nodeList
                        this.currentTag = this.tags[0]
                    }
                }
            })
        },
        getDataType({ deptId }) {
            return deptId ? 'departmentName' : 'userName'
        },
        onSwitchChange(evt) {
            console.log(evt)
            enableTemplate({ id: this.currentTag.id, status: Number(evt) }).then((res) => {
                if (res.code === 200) {
                    this.$message.success(evt ? '激活成功' : '關閉成功')
                    this.currentTag.status = Number(evt)
                }
            })
        },
        refresh() {
            this.getTemplateLiist(true)
        },
        getTags({ target: { value } }) {
            if (!value) {
                this.getTemplateLiist()
            }
            this.tags = this.tags.filter((item) => item.name.includes(value))
        },
    },
}
