//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { selectPerson } from '@/mixins/index'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import selectPersonDialog from '@/views/fileStream/documentManage/weixin/selectPersonDialog'
import nodeAdd from './nodeAdd.vue'
export default {
    components: {
        selectPersonDialog,
        nodeAdd,
        OpenData,
    },
    props: {
        // 接收人
        isReceiveVisebel: {
            type: Boolean,
            default: true,
        },
        // 抄送人
        isCcVisebel: {
            type: Boolean,
            default: true,
        },
    },
    mixins: [selectPerson],
    data() {
        return {
            list: [{ name: '文宣綜合部經理審批', val: [], countersign: 1 }],
            csr_list: [],
            mainList: [],
        }
    },
    computed: {
        allList() {
            let _list = this.list.map((item) => item.val)
            return [...this.mainList, ..._list, ...this.csr_list]
        },
    },
    methods: {
        updataMessage() {
            this.$emit('change', {
                mainList: this.mainList,
                list: this.list,
                csrList: this.csr_list,
            })
        },
        selectPersons(evt) {
            const {
                nodeMess: { index },
                values,
            } = evt
            const obj = {
                name: values.name,
                countersign: values.countersign,
                val: JSON.parse(values.person),
            }
            this.$set(this.list, index, obj)
            this.updataMessage()
        },

        getDataType({ deptId }) {
            return deptId ? 'departmentName' : 'userName'
        },
        initData({ mainList, list, csrList }) {
            this.mainList = mainList
            this.list = list
            this.csr_list = csrList
        },
        reset() {
            this.mainList = []
            this.list = [{ name: '文宣綜合部經理審批', val: [], countersign: 1 }]
            this.csr_list = []
        },
        addDom() {
            this.list.push({ name: '節點', val: [] })
        },
        editDom(ele, index) {
            this.$refs.nodeAdd.open({
                el: ele,
                index,
                allList: this.allList || [],
            })
        },
        delDom(i) {
            this.list.splice(i, 1)
        },
    },
}
